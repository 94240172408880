import './style/index.scss'

import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Route, matchPath } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group'

import Home from './routes/Home'
import About from './routes/About'
import NotFound from './routes/NotFound'
import Preloader from './components/Preloader'

import * as serviceWorker from './serviceWorker'

class App extends React.Component {
  state = {
    ready: false,
    data: null,
  }

  matchPath = path => matchPath(window.location.pathname, path)

  setReady = data => this.setState({ data, ready: true })

  renderPreloader = () => <Preloader key="preloader" setReady={this.setReady} />

  renderRoute = () => {
    const match = this.props.routes.filter(({ path }) => this.matchPath(path))
    const { content, globalData } = this.state.data
    return match.length ? (
      match.map(({ Component, key }) => (
        <Component key={key} content={content[key]} globalData={globalData} />
      ))
    ) : (
      <NotFound key="404" />
    )
  }

  render() {
    const { ready } = this.state
    const renderContent = this[ready ? 'renderRoute' : 'renderPreloader']

    return (
      <div id="app">
        <TransitionGroup id="content">{renderContent()}</TransitionGroup>
      </div>
    )
  }
}

render(
  <BrowserRouter>
    <Route>
      {props => (
        <App
          {...props}
          routes={[
            {
              key: 'home',
              Component: Home,
              path: {
                path: '/',
                exact: true,
              },
            },
            {
              key: 'about',
              Component: About,
              path: {
                path: `/about`,
              },
            },
          ]}
        />
      )}
    </Route>
  </BrowserRouter>,
  document.getElementById('root'),
)

serviceWorker.register()
