import React from 'react'
import withTransition from '../util/withTransition'
import animate from '@jam3/gsap-promise'
import { Expo } from 'gsap'
import { Link } from 'react-router-dom'
import PrismicDOM from 'prismic-dom'
import Footer from '../components/Footer'
import { containerWidth } from '../util/dimensions'

class Home extends React.Component {
  animateIn() {
    return animate.fromTo(
      this.el,
      0.7,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        ease: Expo.easeOut,
      },
    )
  }

  animateOut() {
    return animate.to(this.el, 0.7, {
      autoAlpha: 0,
      pointerEvents: 'none',
      ease: Expo.easeOut,
    })
  }

  render() {
    const { content, globalData } = this.props
    return (
      <div ref={e => (this.el = e)} className="abs fill bg-mid-gray overflow-y">
        <div style={{ maxWidth: containerWidth, margin: '0 auto' }}>
          <h1 className="beatrice dib f24 ph16 mt40 mid-blue">Shanna Schwab</h1>
          <div className="ph16 pt104 pb104 min-vh100">
            <h2 className="intro mid-blue mb64">
              {PrismicDOM.RichText.asText(content.intro)}
            </h2>
            <Link to="/about" className="beatrice mid-blue f24">
              {content.read_more_label}
            </Link>
          </div>
        </div>
        <Footer textColor="green-gray" bgColor="light-blue" {...globalData} />
      </div>
    )
  }
}

export default withTransition(Home)
