import React, { useState, useEffect } from 'react'
import copyToClipboard from '../util/copyToClipboard'
import cx from 'nanoclass'
import { containerWidth } from '../util/dimensions'

export default function Footer({
  textColor,
  bgColor,
  links,
  email_confirmation_text,
}) {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (isActive) {
      const timeoutId = window.setTimeout(() => setIsActive(false), 2000)
      return () => window.clearTimeout(timeoutId)
    }
  })

  const handleClick = ev => {
    const str = 'mailto:'
    const { href } = ev.target
    const isMailTo = href.indexOf(str) > -1
    if (isMailTo) {
      ev.preventDefault()
      const emailAddress = href.slice(str.length)
      copyToClipboard(emailAddress)
      setIsActive(true)
    }
  }

  return (
    <footer className={cx(['f24 p16', `bg-${bgColor}`])}>
      <ul className="l tc">
        {links.map(link => (
          <li key={link.url.url}>
            <a
              className={cx(['beatrice db pv8', textColor])}
              href={link.url.url}
              target={link.url.target}
              onClick={handleClick}
            >
              {link.label}
            </a>
          </li>
        ))}
      </ul>
      <div
        className="fix fill bg-pink ph24 pv4gs0"
        style={{
          transition: 'opacity 0.4s',
          opacity: isActive ? 1 : 0,
          pointerEvents: isActive ? 'auto' : 'none',
        }}
      >
        <div
          class="rel x y"
          style={{ maxWidth: containerWidth, margin: '0 auto' }}
        >
          <h1 className="beatrice dib f24 mt40 dark-blue">Shanna Schwab</h1>
          <div class="abs fill f aic">
            <p className="dark-blue f38">{email_confirmation_text}</p>
          </div>
        </div>
      </div>
    </footer>
  )
}
