import React from 'react'
import withTransition from '../util/withTransition'
import animate from '@jam3/gsap-promise'
import { Expo } from 'gsap'

class NotFound extends React.Component {
  animateIn() {
    return animate.fromTo(
      this.el,
      1,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        ease: Expo.easeOut,
      },
    )
  }

  animateOut() {
    return animate.to(this.el, 1, {
      autoAlpha: 0,
      ease: Expo.easeOut,
    })
  }

  render() {
    return (
      <div ref={e => (this.el = e)} className="abs fill pt80 bg-pink">
        <h1 className="tc green-gray">404</h1>
      </div>
    )
  }
}

export default withTransition(NotFound)
