import React from 'react'
import timeout from '../util/timeout'
import withTransition from '../util/withTransition'
import animate from '@jam3/gsap-promise'
import { Expo } from 'gsap'

import Prismic from 'prismic-javascript'

const apiEndpoint = 'https://shannaschwab.cdn.prismic.io/api/v2'

class Preloader extends React.Component {
  static defaultProps = {
    minDisplayTime: 1000,
  }

  fetchContent() {
    return Prismic.getApi(apiEndpoint)
      .then(api => api.query(''))
      .then(response => response.results, err => console.log(err))
      .then(docs => ({
        globalData: docs.find(doc => doc.type === 'global').data,
        content: docs
          .filter(doc => doc.type !== 'global')
          .reduce((acc, doc) => {
            acc[doc.type] = doc.data
            return acc
          }, {}),
      }))
  }

  animateIn() {
    return Promise.all([
      this.fetchContent(),
      timeout(this.props.minDisplayTime),
      animate.fromTo(
        this.el,
        1,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          ease: Expo.easeOut,
        },
      ),
    ]).then(res => this.props.setReady(res[0]))
  }

  animateOut() {
    return animate.to(this.el, 1, {
      autoAlpha: 0,
      ease: Expo.easeOut,
    })
  }

  render() {
    return (
      <div ref={e => (this.el = e)} className="abs fill pt80 bg-light-blue">
        <h1 className="tc green-gray">Loading...</h1>
      </div>
    )
  }
}

export default withTransition(Preloader)
