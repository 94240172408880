import React from 'react'
import withTransition from '../util/withTransition'
import animate from '@jam3/gsap-promise'
import { Expo } from 'gsap'
import { Link } from 'react-router-dom'
import PrismicDOM from 'prismic-dom'
import Footer from '../components/Footer'
import { containerWidth } from '../util/dimensions'

class About extends React.Component {
  animateIn() {
    return animate.fromTo(
      this.el,
      0.7,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        ease: Expo.easeOut,
      },
    )
  }

  animateOut() {
    return animate.to(this.el, 0.7, {
      autoAlpha: 0,
      pointerEvents: 'none',
      ease: Expo.easeOut,
    })
  }

  render() {
    const { content, globalData } = this.props
    return (
      <div
        ref={e => (this.el = e)}
        className="abs fill bg-light-blue overflow-y"
      >
        <div style={{ maxWidth: containerWidth, margin: '0 auto' }}>
          <Link to="/">
            <h1 className="beatrice dib f24 ph16 mt40 green-gray">
              Shanna Schwab
            </h1>
          </Link>
          <div className="ph16 pt104 pb104 min-vh100">
            <div
              className="bio green-gray mb64"
              dangerouslySetInnerHTML={{
                __html: PrismicDOM.RichText.asHtml(content.bio),
              }}
            />
            <Link to="/" className="beatrice green-gray f24">
              Back Home
            </Link>
          </div>
        </div>
        <Footer textColor="mid-blue" bgColor="mid-gray" {...globalData} />
      </div>
    )
  }
}

export default withTransition(About)
